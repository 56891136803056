import Icon from "@ant-design/icons";
import logoSVG from "~/assets/icons/logo_full.svg?react";

import "./index.scss";

export default function PoweredBy() {
  return (
    <div className="powered-by">
      <a href="https://www.targetpilot.ai" target="_blank" rel="noreferrer">
        <span>
          <span>Powered By </span>

          <Icon component={logoSVG} style={{ height: "20px" }} />
        </span>
      </a>
    </div>
  );
}
