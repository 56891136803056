import Icon from "@ant-design/icons";
import voteFillSVG from "~/assets/icons/upvote_fill.svg?react";
import voteLineSVG from "~/assets/icons/upvote_line.svg?react";
import { getLocalMessageId } from "~/utils";
import { useCustomizeStore, useMessageStore, useBotStore } from "~/stores";
import { FeedbackLog, Message, MessageSubType, MessageType } from "~/types";
import useFeedback, { FeedbackHooksProps } from "./useFeedback";

import { useState } from "preact/compat";
import { FeedbackApiEnum, sendFeedback } from "~/api/chat";
import classNames from "classnames";

import "./index.scss";

interface IProps {
  message: Message;
  sendMessage: (text: string) => void;
}

export default function Feedback({
  sendMessage,
  message,
  hoverRef,
  isBot,
  type,
  subType,
}: IProps & FeedbackHooksProps) {
  const { messageId, feedback } = message;
  const [upvoted, setUpvoted] = useState(feedback === FeedbackLog.UPVOTED);
  const [downvoted, setDownvoted] = useState(feedback === FeedbackLog.DOWNVOTED);
  // const { t } = useTranslation("translation", { keyPrefix: "bot.feedback" });
  const feedbackVisible = useFeedback({ hoverRef, isBot, type, subType });

  const {
    customize: { defaultMessageSetting },
  } = useCustomizeStore();

  const { getBotCode } = useBotStore();
  const botCode = getBotCode();

  // const [messageApi, contextHolder] = antdMessage.useMessage();
  const { appendMessage, updateMessage } = useMessageStore();
  const ifMessageUpvoted = message?.feedbackLog?.log === FeedbackLog.UPVOTED;
  const ifMessageDownvoted = message?.feedbackLog?.log === FeedbackLog.DOWNVOTED;
  const ifMessageFeedbacked = ifMessageUpvoted || ifMessageDownvoted;

  const copy = () => {
    // TODO:这里需要拷贝 Markdown 内容？
    navigator.clipboard.writeText(message.text as string).then(() => {
      // messageApi.open({ type: "success", content: t("copied") });
      // sendFeedbackMessage(FeedbackAction.COPY, messageId as string);
    });
  };

  const upvote = () => {
    const upvoteMessage = defaultMessageSetting.upvote;
    if (!upvoteMessage || ifMessageFeedbacked) return;
    setUpvoted(!upvoted);
    const newMessage: Message = {
      type: MessageType.TEXT,
      subType: MessageSubType.UPVOTE,
      text: upvoteMessage,
      isBot: true,
      messageId: getLocalMessageId(),
    };

    updateMessage({
      ...message,
      feedbackLog: { relatedMessageId: messageId as string, log: FeedbackLog.UPVOTED },
    });

    sendFeedback(botCode, messageId as string, FeedbackApiEnum.LIKE);
    appendMessage(newMessage);
  };

  const downvote = () => {
    const downvoteMessage = defaultMessageSetting.downvote;
    if (!downvoteMessage || ifMessageFeedbacked) return;
    setDownvoted(!downvoted);
    const newMessage: Message = {
      type: MessageType.TEXT,
      subType: MessageSubType.DOWNVOTE,
      text: downvoteMessage,
      isBot: true,
      messageId: getLocalMessageId(),
    };

    updateMessage({
      ...message,
      feedbackLog: { relatedMessageId: messageId as string, log: FeedbackLog.DOWNVOTED },
    });

    sendFeedback(botCode, messageId as string, FeedbackApiEnum.DISLIKE);
    appendMessage(newMessage);
  };

  const regenerate = () => {
    // sendFeedbackMessage(FeedbackAction.REGENERATION, messageId as string);
  };

  if (!feedbackVisible) return null;

  return (
    <div className="feedback">
      {/* <Tooltip title="Copy">
        <div className="copy" onClick={copy}>
          <CopyOutlined />
        </div>
      </Tooltip> */}
      {/* <div className={classNames("item regenerate")} title="Regenerate" onClick={regenerate}>
        <Icon component={refreshLineSVG} />
      </div> */}
      <div
        className={classNames("item upvote", { triggered: upvoted })}
        title="Upvote"
        onClick={upvote}
      >
        <Icon component={upvoted ? voteFillSVG : voteLineSVG} />
      </div>

      <div
        className={classNames("item downvote", { triggered: downvoted })}
        title="Downvote"
        onClick={downvote}
      >
        <Icon component={downvoted ? voteFillSVG : voteLineSVG} />
      </div>
    </div>
  );
}
