export const remove = (data: Record<string, any>, path: string) => {
  const keys = path
    .split(".")
    .map((key) => key.match(/[^[\]]+/g)!)
    .flat();

  let current = data;

  for (let index = 0; index < keys.length; ++index) {
    const key = keys[index];

    if (index === keys.length - 1) {
      delete current[key];
    } else if (current?.[key]) {
      current = current[key];
    } else {
      delete current[key];
    }
  }
};
