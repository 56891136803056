import { useEffect } from "preact/hooks";
import { reportBotDisplay, reportInstalled } from "~/api/integration";
import { useBotStore, useUserStore } from "~/stores";
import { v4 as uuidv4 } from "uuid";

export default function useReportBotInstalled() {
  const {
    info: { tenantId, tenantUnitId, agentId, platform },
  } = useUserStore();
  const { integrationConfig } = useBotStore();

  useEffect(() => {
    reportBotDisplay({
      eventId: `bd-${uuidv4()}`,
      eventName: "bot_display",
      eventData: "",
      eventClient: platform ?? "",
      agentId: agentId,
      timestamp: new Date().toISOString(),
      tenantId: tenantId,
      tenantUnitId: tenantUnitId,
      botCode: integrationConfig.code!,
    });

    reportInstalled({
      botCode: integrationConfig.code!,
      tenantId,
      tenantUnitId,
      domain: window.location.origin,
    });
  }, []);
}
