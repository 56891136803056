import type { ProductCompareItem } from "~/types";
import CompareList from "../ProductList";
import TextMessage from "../TextMessage";

import "./index.scss";

interface IProps {
  text: string;
  data: ProductCompareItem[];
}

export default function ProductCompareMessage({ data, text }: IProps) {
  let dataSources: ProductCompareItem[] | undefined = undefined;

  if (data) {
    dataSources = data.map((item) => {
      const {
        product_id,
        product_price,
        product_brand,
        product_images,
        product_url,
        product_name,
      } = item;

      return {
        product_id,
        product_price,
        product_brand,
        product_images,
        product_url,
        product_name,
      };
    });
  }

  return (
    <div className="product-compare-message">
      {text && <TextMessage isBot={true} text={text} />}

      {dataSources && <CompareList data={dataSources} />}
    </div>
  );
}
