import OkIcon from "../Icon/OkIcon";
import { useCustomizeStore } from "~/stores";
import { useRef } from "preact/compat";
import classnames from "classnames";
import { useHover } from "ahooks";

import "./index.scss";

interface IProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export default function BotCheckbox({ checked = false, onChange }: IProps) {
  const {
    customize: { palettes },
  } = useCustomizeStore();

  const ref = useRef(null);
  const isHovering = useHover(ref);

  const handleChange = () => {
    onChange?.(!checked);
  };

  const hoveredColor = palettes[5];
  const defaultColor = palettes[6];
  const defaultBgColor = "#fff";

  return (
    <div
      className={classnames("uikit-bot-checkbox checkbox", { checked })}
      onClick={handleChange}
      style={{
        border: `1px solid ${isHovering ? "transparent" : defaultColor}`,
        backgroundColor: `${isHovering ? hoveredColor : checked ? defaultColor : defaultBgColor}`,
      }}
      ref={ref}
    >
      <div className="checkbox-value">
        {checked || isHovering ? (
          <div className="ok">
            <OkIcon fill="#fff" width="12" height="12" />
          </div>
        ) : null}
      </div>
    </div>
  );
}
