import { usePrevious } from "ahooks";
import { useEffect, useImperativeHandle, useMemo } from "preact/hooks";
import { type Ref } from "preact/compat";
import { useBotStore } from "~/stores";
import { BotConfigProps } from "~/utils/widgetConfig";

export interface CustomMethods {
  toggleVisibility?: () => void;
  resetBotPosition?: () => void;
}

export default function useCustomElement(
  props: BotConfigProps & {
    botLoading: boolean;
    rootRef: Ref<CustomMethods>;
  }
) {
  const { code, _shadowRoot, eventToDocument, botLoading, rootRef } = props;

  const {
    integrationConfig,
    botVisibleInWidgetMode,
    toggleBotVisibilityInWidgetMode,
    resetBotVisibleInWidgetMode,
    resetBotPosition,
  } = useBotStore();

  const popupHidden = useMemo(
    () => Boolean(integrationConfig.isWidget && !botVisibleInWidgetMode),
    [integrationConfig.isWidget, botVisibleInWidgetMode]
  );
  const prevBotVisibleInWidgetMode = usePrevious(botVisibleInWidgetMode);

  const dispatchCustomToggleEvent = (visible: boolean) => {
    if (!_shadowRoot) return;
    let eventRootElement: HTMLElement | Document = _shadowRoot;
    let eventName = "toggleBotPopup";

    if (eventToDocument) {
      eventRootElement = document;
      eventName = `${code}_toggleBotPopup`;
    }
    eventRootElement.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true,
        detail: { visible: visible },
      })
    );
  };
  // dispatch custom toggle event when visibility change
  useEffect(() => {
    if (integrationConfig.isWidget) {
      if (botLoading) {
        resetBotVisibleInWidgetMode();
        dispatchCustomToggleEvent(false);
        return;
      }
      if (botVisibleInWidgetMode !== prevBotVisibleInWidgetMode && !botLoading) {
        dispatchCustomToggleEvent(Boolean(botVisibleInWidgetMode));
      }
    }
  }, [botLoading, integrationConfig.isWidget, botVisibleInWidgetMode, prevBotVisibleInWidgetMode]);
  // expose methods
  useImperativeHandle(rootRef, () => ({
    toggleVisibility() {
      return toggleBotVisibilityInWidgetMode?.();
    },

    resetBotPosition() {
      resetBotPosition();
    },
  }));
  // change host z-index
  useEffect(() => {
    if (_shadowRoot) {
      _shadowRoot.style.zIndex = "2147483647";
    }
  }, [popupHidden]);

  return { popupHidden };
}
