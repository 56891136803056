import { TrackingResponse } from "~/types/express";
import { TenantParams } from "~/types/request";
import request from "~/utils/request";

export const trackingPackage = (
  params: { order?: string; trackingNumber?: string } & TenantParams
): Promise<TrackingResponse> => {
  return request({
    url: "/packages/tracking",
    params,
  });
};

export const trackingAllPackages = (
  params: { order?: string; trackingNumber?: string } & TenantParams
): Promise<{
  trackInfo: TrackingResponse[] | null;
  orderInfo?: { fulfillmentStatus: string; orderStatusUrl: string };
}> => {
  return request({
    url: "/packages/tracking_all",
    params,
  });
};
