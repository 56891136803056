import { useEffect, useState } from "preact/compat";
import Icon from "@ant-design/icons";
import closeSVG from "~/assets/icons/close.svg?react";
import { useBotStore, useCustomizeStore } from "~/stores";
import { hexToRGBA } from "~/utils";
import { getRandomWelcomeTip } from "~/locales/welcome";
import SettingMenu from "../SettingMenu";

import "./index.scss";

interface IProps {
  name: string;
  color: string;
}

export default function BotHeader({ name }: IProps) {
  const {
    customize: { avatar, palettes },
  } = useCustomizeStore();

  const { integrationConfig, toggleBotVisibilityInWidgetMode } = useBotStore();
  const { hostMode, isWidget } = integrationConfig;
  const [tip, setTip] = useState("");

  function handleMin() {
    toggleBotVisibilityInWidgetMode?.();
  }

  useEffect(() => {
    setTip(getRandomWelcomeTip());
  }, []);

  const prevRGBA = hexToRGBA(palettes[6], 0.1);

  return (
    <div
      className="bot-header-box"
      style={{
        background: `linear-gradient(${prevRGBA}, rgba(255, 255, 255, 0.2))`,
      }}
    >
      <div className="bot-header">
        <div className="agent-box">
          <div className="avatar-box">
            {avatar && <img src={avatar} alt="" />}
            <div className="status"></div>
          </div>
          <div className="desc">
            <span className="name">{name}</span>
            <span className="tip">{tip}</span>
          </div>
        </div>

        <div className="opration-box">
          <SettingMenu />

          {isWidget && (
            <div className="min" onClick={handleMin}>
              <Icon component={closeSVG} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
