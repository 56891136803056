import Icon from "@ant-design/icons";
import { useBotStore, useCustomizeStore, useUserStore } from "~/stores";
import { hexToRGBA, notificationMusic } from "~/utils";
import arrowSVG from "~/assets/icons/arrow.svg?react";
import silentSVG from "~/assets/icons/silent.svg?react";
import chatWhiteSVG from "~/assets/icons/chat_white.svg?react";
import { useEffect, useRef, useState } from "preact/hooks";
import { getBotBubbleMsgRenderTs, setBotBubbleMsgRenderTs } from "~/utils/storage";

import "./index.scss";
import { HOST_MODE } from "~/types";

interface BotBubbleProps {
  draggable?: boolean;
}

const DRAG_MOVING_DISTANCE_THRESHOLD = 5;
const DRAG_MOVING_INTERVAL_THRESHOLD = 300;

const BotBubble: preact.FunctionComponent<BotBubbleProps> = ({ draggable }) => {
  const {
    botVisibleInWidgetMode,
    toggleBotVisibilityInWidgetMode,
    integrationConfig,
    setBotPosition,
  } = useBotStore();
  const {
    customize: { brandColor, defaultMessageSetting },
  } = useCustomizeStore();
  const { isNotificationSoundMuted } = useUserStore();
  const [bubbleMsgVisible, setBubbleMsgVisible] = useState(false);
  const btnRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ts = getBotBubbleMsgRenderTs(integrationConfig.code!);

    if (!ts || JSON.parse(ts) + 24 * 60 * 60 * 1000 < Date.now()) {
      setBubbleMsgVisible(true);
      setBotBubbleMsgRenderTs(integrationConfig.code!, Date.now());
      if (!isNotificationSoundMuted) {
        notificationMusic.play();
      }
    }
  }, []);

  const handleDrag =
    (
      draggableEle: HTMLElement,
      dragVars: {
        isDragging: boolean;
        isMoving: boolean;
        offsetX: number;
        offsetY: number;
        startX: number;
        startY: number;
        startTs: number;
      }
    ) =>
    (e: MouseEvent | TouchEvent) => {
      e.stopPropagation();
      e.preventDefault();

      dragVars.isDragging = true;
      const eventTarget = "targetTouches" in e ? e.targetTouches[0] : e;
      dragVars.offsetX =
        eventTarget.clientX + window.innerWidth - draggableEle.getBoundingClientRect().right;
      dragVars.offsetY =
        eventTarget.clientY + window.innerHeight - draggableEle.getBoundingClientRect().bottom;
      dragVars.startX = eventTarget.clientX;
      dragVars.startY = eventTarget.clientY;
      dragVars.startTs = Date.now();

      const handleMove = (e: MouseEvent | TouchEvent) => {
        if (draggable === false) {
          return;
        }

        const eventTarget = "targetTouches" in e ? e.targetTouches[0] : e;
        if (dragVars.isDragging) {
          const deltaX = eventTarget.clientX - dragVars.startX;
          const deltaY = eventTarget.clientY - dragVars.startY;
          if (Math.sqrt(deltaX * deltaX + deltaY * deltaY) > DRAG_MOVING_DISTANCE_THRESHOLD) {
            dragVars.isMoving = true;
            const pos = {
              right: dragVars.offsetX - eventTarget.clientX,
              bottom: dragVars.offsetY - eventTarget.clientY + 76,
            };

            draggableEle.style.cursor = "grabbing";

            setBotPosition(pos);
          }
        }
      };

      const handleDragEnd = (e: MouseEvent | TouchEvent) => {
        if (Date.now() - dragVars.startTs < DRAG_MOVING_INTERVAL_THRESHOLD && !dragVars.isMoving) {
          toggleBotVisibilityInWidgetMode?.();
        }

        dragVars.isMoving = false;
        dragVars.isDragging = false;
        draggableEle.style.cursor = "pointer";

        document.removeEventListener("targetTouches" in e ? "touchmove" : "mousemove", handleMove);
        document.removeEventListener("targetTouches" in e ? "touchend" : "mouseup", handleDragEnd);
      };

      document.addEventListener("targetTouches" in e ? "touchmove" : "mousemove", handleMove);
      document.addEventListener("targetTouches" in e ? "touchend" : "mouseup", handleDragEnd);
    };

  useEffect(() => {
    const draggableEle = btnRef.current!;

    const handleDragStart = handleDrag(draggableEle, {
      isDragging: false,
      isMoving: false,
      offsetX: 0,
      offsetY: 0,
      startX: 0,
      startY: 0,
      startTs: 0,
    });

    draggableEle.addEventListener(
      integrationConfig.hostMode === HOST_MODE.MOBILE ? "touchstart" : "mousedown",
      handleDragStart
    );

    return () =>
      draggableEle.removeEventListener(
        integrationConfig.hostMode === HOST_MODE.MOBILE ? "touchstart" : "mousedown",
        handleDragStart
      );
  }, [integrationConfig.hostMode]);

  return (
    <div className="tp-chat-entry">
      {defaultMessageSetting.welcome && !botVisibleInWidgetMode && bubbleMsgVisible && (
        <div
          className="tp-chat-bubble"
          onClick={() => {
            toggleBotVisibilityInWidgetMode?.();
            setBubbleMsgVisible(false);
          }}
        >
          {defaultMessageSetting.welcome}
        </div>
      )}
      <div
        className="chat-round-btn"
        style={{
          backgroundColor: brandColor,
          boxShadow: `0px 0px 10px 5px ${hexToRGBA(brandColor, 0.2)}`,
        }}
        ref={btnRef}
      >
        <Icon
          component={botVisibleInWidgetMode ? arrowSVG : chatWhiteSVG}
          className="status-icon"
        />
        {isNotificationSoundMuted && <Icon component={silentSVG} className="silent-icon" />}
      </div>
    </div>
  );
};

export default BotBubble;
