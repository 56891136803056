import classNames from "classnames";
import { useMemo, useState } from "preact/compat";
import { useBotStore } from "~/stores";
import { ProductCompareItem } from "~/types";
import ProductListItem from "./Item";

import "./index.scss";

interface IProps {
  data: ProductCompareItem[];
  isHistory?: boolean;
}

export default function ProductList({ data, isHistory }: IProps) {
  const minCollapseCount = 2;
  const { removeCompareData, pushCompareData, compareData } = useBotStore();
  const [collapse, setCollapse] = useState(data.length > minCollapseCount);

  const checkedList = useMemo(() => compareData.map((item) => item.product_id), [compareData]);

  function onCheckboxChange(index: number) {
    const productId = data[index].product_id;
    if (checkedList.includes(productId)) {
      removeCompareData(productId);
    } else {
      pushCompareData(data[index]);
    }
  }

  return (
    <div className="box-message-compare-list-box">
      {data.map((item, index) => {
        const checked = checkedList.includes(item.product_id);
        const shouldRender = collapse ? index < minCollapseCount : true;
        return (
          <ProductListItem
            key={index}
            index={index}
            data={item}
            checked={checked}
            onCheckboxChange={onCheckboxChange}
            shouldRender={shouldRender}
            isHistory={isHistory}
          />
        );
      })}

      {data.length > minCollapseCount && (
        <div className="flex-area">
          <div className="flex-trigger">
            <div onClick={() => setCollapse(!collapse)}>
              <div className={classNames("arrow", { rotate: !collapse })}>
                <svg
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.69925 16.8293C10.3458 16.1109 11.4523 16.0527 12.1707 16.6993L21 24.6456L29.8293 16.6993C30.5477 16.0527 31.6542 16.1109 32.3008 16.8293C32.9473 17.5477 32.8891 18.6542 32.1707 19.3008L22.1707 28.3008C21.5052 28.8998 20.4949 28.8998 19.8293 28.3008L9.82933 19.3008C9.11093 18.6542 9.0527 17.5477 9.69925 16.8293Z"
                    fill="#666666"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
