import { useCustomizeStore } from "~/stores";

import "./styles.scss";

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  htmlType?: "submit" | "reset" | "button";

  onClick?: (e?: MouseEvent) => void;
}

export default function UIKitButton({ children, onClick, style, htmlType = "button" }: IProps) {
  const {
    customize: { palettes },
  } = useCustomizeStore();

  return (
    <button
      type={htmlType}
      className="uikit-button"
      style={{
        background: palettes[5] ?? "#00B395",
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
