import { useEffect, useLayoutEffect, useReducer } from "preact/hooks";
import { cloneElement, FunctionComponent } from "preact";
import { ChangeEvent, ReactElement } from "preact/compat";
import classNames from "classnames";

import { useFormContext } from "./FormContext";
import { InternalFormInstance } from "./FormStore";

export type IRule = (
  | {
      required: boolean;
    }
  | { max: number }
  | { min: number }
) & {
  message?: string;
};

export interface IFormItemProps {
  name: string;
  label?: string;
  rules?: IRule[];
  trigger?: string;
  isRequired?: boolean;

  children: ReactElement;
}

const FormItem: FunctionComponent<IFormItemProps> = ({
  children,
  label,
  name,
  rules,
  trigger = "onChange",
}) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { form } = useFormContext();
  const error = form.getFieldError(name);

  useLayoutEffect(() => {
    const unregister = (form as InternalFormInstance)
      .getInternalHooks()
      .registerField(name, { name, rules });

    return unregister;
  }, [name]);

  const controlledElement = cloneElement(children, {
    value: form.getFieldValue(name),

    [trigger]: (e: ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(name, e.currentTarget?.value);
    },
  });

  useEffect(() => {
    const off = form.onFieldChange(name, () => {
      forceUpdate(Math.random() + Date.now());
    });

    return off;
  }, [name]);

  return (
    <div className={classNames(["form-item", { error: !!error }])}>
      <div className="form-item-row">
        {label && <div className="form-item-label">{label}</div>}
        <div className="form-item-content">{controlledElement}</div>
      </div>
      {error && <div className="form-item-help">{error}</div>}
    </div>
  );
};

export default FormItem;
