import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Icon from "@ant-design/icons";
import expandSVG from "~/assets/icons/expand_gradient.svg?react";
import sendSVG from "~/assets/icons/send_gradient.svg?react";

import "./index.scss";
import useModeClassName from "~/hooks/useModeClassName";
import classnames from "classnames";

export default function LoadingSkeleton({ forceHide }: { forceHide?: boolean }) {
  const modeClassName = useModeClassName();

  return (
    <div
      className={classnames("loading-skeleton-box tp-scroll-bar", { ...modeClassName })}
      style={forceHide ? { visibility: "hidden" } : {}}
    >
      <div className="header">
        <div className="avatar">
          <Skeleton circle height="100%" containerClassName="avatar-skeleton" />
          <Skeleton circle height="100%" containerClassName="status" />
        </div>

        <div className="info">
          <Skeleton height="100%" containerClassName="info-name-skeleton" />
          <Skeleton height="100%" containerClassName="info-status-skeleton" />
        </div>

        <div className="grow"></div>

        <Icon component={expandSVG} />
      </div>

      <div className="waitting">
        <div className="line left"></div>
        <Skeleton height="100%" containerClassName="more-skeleton" />
        <div className="line"></div>
      </div>

      <div className="content">
        <div className="bot">
          <Skeleton circle height="100%" containerClassName="avatar-skeleton" />
          <div className="messages">
            <Skeleton height="100%" containerClassName="top-skeleton" />
            <div className="middle">
              <Skeleton height="100%" containerClassName="left-skeleton" />
              <Skeleton height="100%" containerClassName="right-skeleton" />
            </div>
            <Skeleton height="100%" containerClassName="bottom-skeleton" />
          </div>
        </div>
        <div className="user">
          <Skeleton height="100%" />
          <Skeleton height="100%" />
          <Skeleton height="100%" />
        </div>
      </div>

      <div className="edit">
        <div>
          <div className="input-area">
            <Skeleton height="100%" containerClassName="input-skeleton" />
            {/* <Skeleton height="100%" circle containerClassName="send-skeleton" /> */}
            <Icon component={sendSVG} />
          </div>
          <div className="powered">POWERED BY TARGETPILOT</div>
        </div>
      </div>
    </div>
  );
}
