import Icon from "@ant-design/icons";
import arrowSVG from "~/assets/icons/arrow_02.svg?react";

import FetchMoreLoading from "./FetchMoreLoading";
import { useCustomizeStore } from "~/stores";
import { useRef } from "preact/compat";
import { useHover } from "ahooks";

import "./index.scss";

interface IProps {
  ifHasMoreMessage: boolean;
  fetchMoreMessages: () => void;
  fetchHistoryPending: boolean;
}

export default function FetchMore({
  ifHasMoreMessage,
  fetchMoreMessages,
  fetchHistoryPending,
}: IProps) {
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const {
    customize: { palettes },
  } = useCustomizeStore();

  if (!ifHasMoreMessage) return null;

  return (
    <div className="fetch-more-message">
      {fetchHistoryPending ? (
        <FetchMoreLoading />
      ) : (
        <div
          className="trigger"
          ref={ref}
          style={{ color: isHovering ? palettes[6] : "unset" }}
          onClick={fetchMoreMessages}
        >
          <span>click view historical messages</span>
          <Icon component={arrowSVG} style={{ color: isHovering ? palettes[6] : "unset" }} />
        </div>
      )}
    </div>
  );
}
