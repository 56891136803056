import { HistoryMessagesResponse, PaginationRequestParams } from "~/types";
import { CreatePackageMessageBody } from "~/types/express";
import request from "~/utils/request";

export const createConversation = (botCode: string) =>
  request({
    url: "/conversations",
    method: "POST",
    data: {
      topic: "chat",
    },
    params: { botCode },
  });

export const getHistoryMessages = (
  botCode: string,
  { page = 1, pageSize = 10 }: PaginationRequestParams
): Promise<HistoryMessagesResponse> =>
  request({
    url: "/messages",
    params: {
      botCode,
      page,
      pageSize,
    },
  });

export const getLinkPreview = (botCode: string, url: string) =>
  request({
    url: "/messages/linkPreview",
    params: {
      botCode,
      url,
    },
  });

export enum FeedbackApiEnum {
  LIKE = "like",
  DISLIKE = "dislike",
}

export const sendFeedback = (botCode: string, runId: string, feedback: FeedbackApiEnum) =>
  request({
    url: "/messages/feedback",
    method: "POST",
    params: {
      botCode,
      runId,
    },
    data: {
      feedback,
    },
  });

export const createPackageMessage = (
  conversationId: string,
  botCode: string,
  data: CreatePackageMessageBody
) => {
  return request({
    url: `/conversations/${conversationId}/messages`,
    method: "POST",
    data,
    params: { botCode },
  });
};
