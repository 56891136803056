import { isWidgetMode, widgetParams, getHostMode } from "~/utils/host";
import { useBotStore } from "~/stores";
import { useParams } from "wouter-preact";
import { useEffect } from "preact/hooks";
import { HOST_MODE, RENDER_MODE } from "~/types";
import { IntegrationConfig } from "~/stores/bot";
import { getBotCodeFromUrl } from "~/utils";
import { BotConfigProps } from "~/utils/widgetConfig";
import { getBotPosition } from "~/utils/storage";

export default function useIntegrationConfig(props?: Partial<BotConfigProps>) {
  const { updateIntegrationConfig, setBotPosition, integrationConfig } = useBotStore();
  let { botCode } = useParams();
  const hostMode = getHostMode();

  useEffect(() => {
    let config: Partial<IntegrationConfig>;

    const forceWidget = props?.forceWidget || props?.forceRender === "widget";

    if (forceWidget || isWidgetMode) {
      // widget mode always render in float mode
      config = {
        ...widgetParams,
        hostMode,
        isWidget: forceWidget ? true : isWidgetMode,
        renderMode: RENDER_MODE.FLOAT,
      };
    } else {
      if (!botCode) {
        botCode = getBotCodeFromUrl();
      }
      config = { code: botCode, hostMode };
      if (hostMode !== HOST_MODE.IFRAME) {
        if (props?.forceRender === "pc") {
          config.hostMode = HOST_MODE.DESKTOP;
        } else if (props?.forceRender === "mobile") {
          config.hostMode = HOST_MODE.MOBILE;
        }
      }
      // pc / iframe mode always render in full mode
      if (config.hostMode !== HOST_MODE.MOBILE) {
        config.renderMode = RENDER_MODE.FULL;
      }
    }

    if (props) {
      config = { ...config, ...props };
    }
    updateIntegrationConfig(config);

    setBotPosition(getBotPosition(config.code!));
  }, []);

  return integrationConfig;
}
