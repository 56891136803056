import type { ProductCompareItem } from "~/types";

import ProductList from "../ProductList";
import TextMessage from "../TextMessage/index";
import "./index.scss";

interface IProps {
  text: string;
  data: ProductCompareItem[];
  isHistory?: boolean;
}

export default function ProductSearchItem({ text, data, isHistory }: IProps) {
  let productSearchList = data;

  return (
    <div className="product-search-item-box">
      {text && <TextMessage isBot={true} text={text} />}
      {productSearchList && (
        <div className="item-list">
          <ProductList data={productSearchList} isHistory={isHistory} />
        </div>
      )}
    </div>
  );
}
