import register from "./Preact2WC";
import "~/styles/reset.css";
import "~/styles/index.scss";

import Bot from "~/components/Bot";

export interface BotWidgetOptionalProps {
  w?: number | string;
  h?: number | string;
  x?: number | string;
  y?: number | string;
  name?: string;
  avatar?: string;
  theme?: string;
  welcome?: string;
  externalUserId?: string;
}

register(
  Bot,
  "target-pilot-bot",
  ["code", "w", "h", "x", "y", "name", "avatar", "theme", "welcome", "externaluserid"],
  USE_SHADOW_DOM
    ? {
        shadow: true,
        mode: "open",
        cssText: window.__TpBotCSS || "",
      }
    : { shadow: false }
);
