import "./index.scss";
import useTime, { TimeHooksProps } from "./useTime";
import classnames from "classnames";

export default function MessageCreatedTime({ isBot, type, time, hoverRef }: TimeHooksProps) {
  const { visible, value, isYesterday, limitRange } = useTime({ time, isBot, hoverRef, type });

  if (!limitRange) return null;

  return (
    <div className={classnames("message-time", { visible })}>
      <span>{isYesterday ? "Yesterday" : ""}</span>
      <span>{value}</span>
    </div>
  );
}
