import { FunctionComponent } from "preact";
import { ChangeEvent } from "preact/compat";

import "./styles.scss";

export interface IInputProps {
  value?: string;
  placeholder?: string;
  defaultValue?: string;

  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input: FunctionComponent<IInputProps> = ({ onChange, ...props }) => {
  return <input className="tp-input" {...props} onChange={(e) => onChange?.(e)} />;
};

export default Input;
