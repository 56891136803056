import { FunctionComponent } from "preact";
import { ReactNode, useEffect, useMemo, useState } from "preact/compat";
import classNames from "classnames";

import "./styles.scss";

export interface ITabsProps {
  activeKey?: string;
  defaultActiveKey?: string;
  items: {
    key: string;
    label: string;
    children: ReactNode;
  }[];
}

const getActiveKey = (props: ITabsProps) => {
  return props.activeKey ?? props.defaultActiveKey ?? props.items[0]?.key;
};

const Tabs: FunctionComponent<ITabsProps> = (props) => {
  const initialActiveKey = getActiveKey(props);
  const [activeKey, setActiveKey] = useState(initialActiveKey);
  const activedKeys = useMemo(() => new Set([initialActiveKey]), []);

  useEffect(() => {
    setActiveKey(getActiveKey(props));
    activedKeys.add(getActiveKey(props));
  }, [props.activeKey]);

  return (
    <div className="tp-tabs">
      <div className="tp-tabs-nav">
        {props.items.map((item) => (
          <div
            className="tp-tabs-tab"
            key={item.key}
            onClick={() => {
              setActiveKey(item.key);
              activedKeys.add(item.key);
            }}
          >
            {item.label}
          </div>
        ))}
        <div
          className="tp-tabs-nav-indicator"
          style={{
            width: `${(1 / props.items.length) * 100}%`,
            transform: `translateX(${props.items.findIndex((item) => item.key === activeKey) * 100}%)`,
          }}
        ></div>
      </div>
      <div className="tp-tabs-content">
        {props.items.map((item) => {
          if (!activedKeys.has(item.key)) {
            return;
          }

          return (
            <div
              key={item.key}
              className={classNames([
                `tp-tabs-tabpane-${item.key === activeKey ? "active" : "hidden"}`,
              ])}
            >
              {item.children}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tabs;
