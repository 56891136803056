import { type StateCreator } from "zustand";
import { setBotNotificationMuted } from "~/utils/storage";

export interface UserInfo {
  agentId: string;
  platform?: string;
  tenantId: string;
  tenantUnitId: string;
}

export interface UserStore {
  info: UserInfo;
  updateInfo: (info: UserInfo) => void;
  clearInfo: () => void;

  isNotificationSoundMuted: boolean;
  muteNotificationSound(code: string, muted: boolean): void;
  muteNotificationSoundByRemote(code: string, muted: boolean): void;
}

const defaultInfo = {
  tenantId: "",
  agentId: "",
  tenantUnitId: "",
};

const userStore: StateCreator<UserStore> = (set, get) => ({
  info: defaultInfo,

  isNotificationSoundMuted: false,

  updateInfo: (info: UserInfo) => {
    set((state) => ({ ...state, info }));
  },

  clearInfo: () => {
    set(() => ({
      info: defaultInfo,
    }));
  },

  muteNotificationSound(code: string, muted: boolean) {
    setBotNotificationMuted(code, muted);
    set((state) => ({ ...state, isNotificationSoundMuted: muted }));
  },
  muteNotificationSoundByRemote(code: string, muted: boolean) {
    set((state) => ({ ...state, isNotificationSoundMuted: muted }));
  },
});

export default userStore;
