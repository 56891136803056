import classNames from "classnames";
import dayjs from "dayjs";
import { FunctionComponent } from "preact";
import { TrackingResponse } from "~/types/express";

export interface IExpressProgressProps {
  data: TrackingResponse;
}

const StatusDescription = {
  NotFound:
    "The inquiry is made successfully but nothing can be found for this tracking number. See sub-statues for more details.",
  InfoReceived:
    "The carrier has received the order info and is about to pick up the package from the sender.",
  InTransit: "The package is in transit. See sub-status for more details.",
  Expired: "The package has been in transit for a long time and not yet delivered.",
  AvailableForPickup: "The package has arrived at the pick up point of the destination.",
  OutForDelivery: "The package is out for delivery.",
  DeliveryFailure:
    "The package was attempted to be delivered but not successfully failed. Please refer to the sub-status for the reason. The reasons may be: the recipient is not at home at the time of delivery, the delivery is delayed and is now rescheduled for another attempt, the recipient requests to postpone the delivery, the address is unknown and cannot be delivered, no delivery service is available due to remote areas, etc.",
  Delivered: "The package is delivered and signed by the recipient.",
  Exception:
    "The package may be returned, please refer to the sub-status for the reason. Reasons may be: recipient's address is wrong or unknown, recipient refuses to accept, parcel is unclaimed beyond the retention period, etc. The package may be detained by customs, common reasons for detention are: containing sensitive prohibited, restricted import and export items, unpaid taxes, etc. Parcels may suffer damage, loss, delayed delivery and other special circumstances in transit.",
} as const;

type StatusEnum = keyof typeof StatusDescription;

const renderTitle = (status: StatusEnum, date: string | null) => {
  if (status === "Delivered") {
    return `Delivered - Time of delivery: ${date}`;
  }

  return StatusDescription[status];
};

const ExpressProgress: FunctionComponent<IExpressProgressProps> = ({ data }) => {
  const currentDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
  const timezoneOffset = new Date().getTimezoneOffset();

  return (
    <>
      <div className="tp-express-card-title">
        {renderTitle(data?.latestStatus.status as StatusEnum, data?.latestEvent.timeRaw.date)}
      </div>
      <div className="tp-express-card-content">
        <div className="tp-express-card-result">{data?.latestEvent.description}</div>
        <div className="tp-express-card-status">
          {/* <img className="logo" /> */}
          <div>
            {data?.tracking.providers[0].provider.name} -{" "}
            {data?.tracking.providers[0].provider.country} - Sync Time: {currentDate} (GMT
            {timezoneOffset > 0 ? "-" : "+"}
            {`${Math.abs(timezoneOffset) / 60}`.padStart(2, "0")}
            :00)
          </div>
        </div>
        <div className="tp-express-card-progress">
          {data?.tracking.providers[0].events.map((item, index) => {
            return (
              <div className="tp-express-card-progress-item">
                <div className="tp-express-card-progress-item-left">
                  <div
                    className={classNames("tp-express-card-progress-dot", { active: index === 0 })}
                  />
                  {data?.tracking.providers[0].events.length - 1 !== index && (
                    <div className="tp-express-card-progress-line" />
                  )}
                </div>
                <div className="tp-express-card-progress-text">
                  <div>
                    {item.timeRaw.date} {item.timeRaw.time}
                  </div>
                  <div>
                    {item.location ? `${item.location},` : ""} {item.description}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ExpressProgress;
