import { FunctionComponent } from "preact";
import { useMemo } from "preact/hooks";
import { ReactNode } from "preact/compat";

import FormItem from "./FormItem";
import FormStore, { FormInstance } from "./FormStore";
import { FormContext } from "./FormContext";

import "./styles.scss";

interface IFormProps {
  form: FormInstance;
  children: ReactNode;
  initialValues?: any;

  onFinish?: (values: any) => void;
  onFinishFailed?: (values: any) => void;
}

interface IForm extends FunctionComponent<IFormProps> {
  Item: typeof FormItem;

  useForm: (form?: FormInstance) => [FormInstance];
}

const Form: IForm = ({ form, children, onFinish, onFinishFailed }) => {
  return (
    <form
      className="form"
      onSubmit={(e) => {
        e.preventDefault();

        form.validateFields();

        const errors = form.getFieldErrors();
        const values = form.getFieldValues();

        if (errors.length > 0) {
          onFinishFailed?.({ errors, values });
        } else {
          onFinish?.(values);
        }
      }}
    >
      <FormContext.Provider value={{ form: form }}>{children}</FormContext.Provider>
    </form>
  );
};

Form.Item = FormItem;

Form.useForm = (form?: FormInstance) => {
  const formInstance = useMemo(() => {
    if (!form) {
      const formStore = new FormStore();
      return formStore.getForm();
    }

    return form;
  }, []);

  return [formInstance];
};

export default Form;
