import { BotConfig, HOST_MODE } from "~/types";

export function isWidgetScript() {
  return document.currentScript?.getAttribute("src")?.includes("/widget");
}

export function getScriptParams(): BotConfig & Record<string, string> {
  const scriptTag = document.currentScript;

  const src = scriptTag?.getAttribute("src");

  if (!src) return {};

  const scriptUrl = new URL(src);

  return Object.fromEntries(scriptUrl.searchParams);
}

export function isMobileDevice() {
  // 获取用户代理字符串
  const userAgent = navigator.userAgent;

  // 判断用户代理字符串中是否包含常见的移动设备标识
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

export function isIframe() {
  return window.self !== window.top;
}

export const isWidgetMode = isWidgetScript();
export const widgetParams = getScriptParams();

export const isMobileMode = isMobileDevice();
export const isIframeMode = isIframe();

export function getHostMode() {
  if (isIframeMode) {
    return HOST_MODE.IFRAME;
  }
  if (isMobileMode) {
    return HOST_MODE.MOBILE;
  }
  return HOST_MODE.DESKTOP;
}
