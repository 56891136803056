import { reportBehavior } from "~/api/report";
import { useBotStore, useCustomizeStore } from "~/stores";
import { ReportBehavior } from "~/types";

import "./index.scss";

interface IProps {
  sendComparisionMessage: () => Promise<unknown>;
}

export default function CompareBar({ sendComparisionMessage }: IProps) {
  const { compareData, setCompareData, getBotCode } = useBotStore();
  const {
    customize: { palettes },
  } = useCustomizeStore();

  const triggerCompare = async () => {
    await sendComparisionMessage();
    reportBehavior({
      botCode: getBotCode(),
      productIds: compareData.map((product) => product.product_id),
      interactionType: ReportBehavior.COMPARE,
    });
    setCompareData([]);
  };

  const clearCompareData = () => {
    setCompareData([]);
  };

  if (!compareData.length) {
    return null;
  }

  return (
    <div className="compare-bar-box">
      <ImageList
        images={compareData.map((item) => item.product_images[0].url)}
        color={palettes[3]}
      />
      {compareData.length > 1 && (
        <div className="actions">
          <div className="item" onClick={triggerCompare}>
            Compare
          </div>
          <div className="item" onClick={clearCompareData}>
            Cancel
          </div>
        </div>
      )}
    </div>
  );
}

function ImageList({ images, color }: { images: string[]; color: string }) {
  const step = 3;

  return (
    <div className="image-list">
      {images.map((item, index) => {
        if (index > step) {
          return null;
        }

        if (index === step) {
          return (
            <div className="item count-box" key={index} style={{ border: `1px solid ${color}` }}>
              <span style={{ color }}>+{images.length - step}</span>
            </div>
          );
        }

        return (
          <div className="item image-item" key={index} style={{ border: `1px solid ${color}` }}>
            <img src={item} alt="" />
          </div>
        );
      })}
    </div>
  );
}
