import { useBotStore } from "~/stores";
import { isWidgetMode, widgetParams } from "./host";

export function getLocalMessageId() {
  return `local-${Date.now()}`;
}

export function isValidURL(url: string) {
  // 正则表达式模式，检查链接是否以 http://、https://、ftp://、// 或 www. 开头
  var pattern = /^(http:\/\/|https:\/\/|ftp:\/\/|\/\/|www\.)/i;

  // 使用 test 方法检查链接是否匹配模式
  return pattern.test(url);
}

export function hexToRGBA(hex: string = "#00B395", alpha: number) {
  // 去除可能包含的 # 号
  hex = hex.replace("#", "");

  // 拆分十六进制颜色值为红、绿、蓝通道
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  // 如果提供了 alpha 参数，则添加透明度
  var a = alpha || 1;

  // 返回 RGBA 格式字符串
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function asyncForEach<T>(array: T[], callback: (item: T) => void, delay: number): void {
  let index = 0;

  function next(): void {
    if (index < array.length) {
      callback(array[index]);
      index++;
      setTimeout(next, delay);
    }
  }

  next();
}

// 创建一个延迟函数
export const delay = (ms: number = 0) => new Promise((resolve) => setTimeout(resolve, ms));

export function getBotCodeFromUrl() {
  const searchParams = new URLSearchParams(window.location.href);
  const code = searchParams.get("code") || "";
  if (code) {
    return code;
  }

  return window.location.pathname.split("/")[1];
}

export const notificationMusic = new Audio(
  "https://tpstorageprodeastus.blob.core.windows.net/target-pilot-public-prod/bot_notification1.wav"
);
notificationMusic.loop = false;
