import { useMessageStore } from "~/stores";
import "./styles.scss";
import { MessageType } from "~/types";

const ToolsBar = () => {
  const { appendMessage } = useMessageStore();

  const handleTrackOrder = () => {
    appendMessage({
      type: MessageType.PACKAGE_TRACKING,
      metadata: {},
      isBot: true,
    });
  };

  return (
    <div className="tools-bar">
      <div className="tool-item" onClick={handleTrackOrder}>
        Track Order
      </div>
    </div>
  );
};

export default ToolsBar;
