import { useHover } from "ahooks";
import classnames from "classnames";
import PReact, { useRef, useState } from "preact/compat";
import { useCustomizeStore } from "~/stores";
import { RecommendQuestion, RecommendQuestionType } from "~/types";

import "./index.scss";

interface RecommendQuestionsProps {
  recommendQuestions: RecommendQuestion[];
  sendTextMessage: (text: string) => void;
}

interface ItemState {
  key: string | number;
  data: RecommendQuestion;
  status: "active" | "init";
}

interface IItemProps {
  item: ItemState;

  onClick: (item: ItemState) => void;
}

const RecommendQuestionItem: PReact.FC<IItemProps> = ({ item, onClick }) => {
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const {
    customize: { palettes },
  } = useCustomizeStore();

  const active = item.status === "active";

  if (item.data.type === RecommendQuestionType.TEXT) {
    return (
      <div
        onClick={() => onClick(item)}
        className={classnames({ active })}
        style={{
          backgroundColor: active ? palettes[2] : palettes[1],
          color: isHovering ? palettes[5] : active ? palettes[7] : palettes[6],
        }}
        ref={ref}
      >
        {item.data.content}
      </div>
    );
  }

  return null;
};

const RecommendQuestions: PReact.FC<RecommendQuestionsProps> = ({
  recommendQuestions,
  sendTextMessage,
}) => {
  const [list, setList] = useState<ItemState[]>(() =>
    recommendQuestions.map((item, index) => ({
      key: index,
      data: item,
      status: "init",
    }))
  );

  if (list.length === 0) {
    return null;
  }

  return (
    <div className="recommend-questions">
      {list.map((item, index) => (
        <RecommendQuestionItem
          item={item}
          key={index}
          onClick={(activeItem) => {
            if (activeItem.data.type === RecommendQuestionType.TEXT) {
              sendTextMessage(activeItem.data.content);

              setList((list) => {
                return list.map((item) => {
                  if (activeItem.key === item.key) {
                    return { ...item, status: "active" };
                  }
                  return item;
                });
              });
            }
          }}
        />
      ))}
    </div>
  );
};

export default RecommendQuestions;
