import classNames from "classnames";
import { Platform, ProductCompareItem, ReportBehavior } from "~/types";
import BotCheckbox from "~/UIKit/Checkbox";
import { useBotStore, useCustomizeStore } from "~/stores";
import { useRef, useState } from "preact/compat";
import { reportBehavior } from "~/api/report";
import useInView from "./useInView";

interface IProps {
  checked?: boolean;
  index: number;
  onCheckboxChange: (checked: number) => void;
  data: ProductCompareItem;
  shouldRender: boolean;
  isHistory?: boolean;
}

export default function ProductListItem({
  data,
  index,
  checked,
  shouldRender,
  onCheckboxChange,
  isHistory,
}: IProps) {
  const [hovered, setHovered] = useState(false);
  const observeItemRef = useRef<HTMLDivElement>(null);

  const {
    customize: { palettes },
  } = useCustomizeStore();
  useInView(observeItemRef, data.product_id, isHistory as boolean);

  const { getBotCode } = useBotStore();

  function handleItemClick(event: MouseEvent, url: string) {
    const target = event.target as HTMLElement;
    // 如果点击的是 checkbox，不做任何操作 uikit-bot-checkbox
    if (target.classList.contains("uikit-bot-checkbox")) return;
    reportBehavior({
      botCode: getBotCode(),
      productIds: [data.product_id],
      interactionType: ReportBehavior.CLICK,
      sourcePlatform: Platform.SELF,
    });
    window.open(url, "_blank");
  }

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      className={classNames("product-list-item item", { hide: !shouldRender, checked })}
      style={{
        borderColor: checked || hovered ? palettes[5] : "transparent",
        boxShadow: checked || hovered ? "0px 4px 4px 0px rgba(0, 0, 0, 0.08)" : "none",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={observeItemRef}
    >
      <BotCheckbox checked={checked} onChange={() => onCheckboxChange(index)} />
      <div className="product-item" onClick={(e) => handleItemClick(e, data.product_url)}>
        <div className="image-box">
          {data.product_images?.[0]?.url && <img src={data.product_images[0].url} alt="" />}
        </div>
        <div className="desc-box">
          <div className="desc">{data.product_name}</div>
          <div className="price">
            <span>$</span>
            <span>{data.product_price}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
