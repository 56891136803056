import { FunctionComponent } from "preact";
import classNames from "classnames";

import "./styles.scss";

export interface StepLoadingProps {
  size?: "small" | "medium" | "large";
}

const StepLoading: FunctionComponent<StepLoadingProps> = ({ size = "small" }) => {
  return (
    <div className={classNames(["load-forty-three", size])}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default StepLoading;
