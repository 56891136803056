import "./index.scss";

export default function SendLoading() {
  return (
    <div className="bouncingLoader-main">
      <div className="load-bouncing-Loader">
        <div></div>
      </div>
    </div>
  );
}
