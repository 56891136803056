import { createConversation } from "~/api/chat";
import { getLocalConversationId, setLocalConversationId } from "~/utils/storage";
import { MessageItem, MessageType } from "~/types";

export async function getConversationId(code: string) {
  const localConversationId = getLocalConversationId(code);
  if (localConversationId) {
    return localConversationId;
  }

  const { id: conversationId } = (await createConversation(code)) as unknown as { id: string };

  setLocalConversationId(code, conversationId);

  return conversationId;
}

export function getRealPayloadArray(str: string) {
  const lines = str.split("\n");
  const result = [];
  let currentRecord = "";

  for (const line of lines) {
    // 将 data: 后面的真正数据提取出来
    if (line.startsWith("data: ")) {
      if (currentRecord !== "") {
        result.push(currentRecord);
      }
      currentRecord = line.substring(6);
    } else {
      currentRecord += line;
    }
  }

  // Add the last record
  if (currentRecord !== "") {
    result.push(currentRecord);
  }

  return result;
}

// 根据 stream 返回的消息串，组装出前端需要的消息格式 MessageItem
export function packMessageItem(parsed: any): MessageItem {
  let text = "";

  const { event, run_id: id, answer, metadata, sequence_number: sequenceNumber, stage } = parsed;
  const done = event === "entire_answer" || event === "no_credit";

  let type = MessageType.TEXT;

  // 针对 no_credit 单独处理
  if (event === "no_credit") {
    text = parsed.notification;
    type = MessageType.NO_CREDIT;
  }
  // 单独将 loading step 类型的消息提取出来
  else if (event === "prepare_stage") {
    return {
      id,
      type: MessageType.LOADING_STEP,
      loadingSteps: [stage],
    };
  } else if (event === "partial_answer") {
    text = answer;
  } else if (event === "entire_answer") {
    type = parsed.type;
    text = parsed?.data?.answer || "";

    if (type === MessageType.CONTENT_FILTERED) {
      text = parsed.notification;
    }
  }

  if (text) {
    // 将之前转换的字符转换回来
    text = text.replace(/\\n/g, "\n").replace(/\\r/g, "\r").replace(/\\t/g, "\t");
  }

  let message: MessageItem = {
    id,
    type,
    text,
    done,
  };

  if (sequenceNumber) {
    message.sequenceNumber = sequenceNumber;
  }

  if (metadata?.resource) {
    message.metadata = {
      resource: metadata.resource,
    };
  }

  if (
    message.type === MessageType.QUESTION_RECOMMENDR ||
    message.type === MessageType.PACKAGE_TRACKING
  ) {
    message.metadata = metadata;
  }

  return message;
}
