import { useBotStore } from "~/stores";
import { HOST_MODE } from "~/types";

interface ModeClassName {
  "iframe-mode"?: boolean;
  "widget-mode"?: boolean;
  "mobile-mode"?: boolean;
  "pc-full-mode"?: boolean;
}

export default function useModeClassName(): ModeClassName {
  const { integrationConfig } = useBotStore();
  const { hostMode, isWidget } = integrationConfig;

  // Style mode following such priority: 1. widget and or mobile mode > 2. PC full mode > 3. iframe mode
  if (!isWidget) {
    if (hostMode === HOST_MODE.DESKTOP) {
      return { "pc-full-mode": true };
    }

    if (hostMode === HOST_MODE.IFRAME) {
      return { "iframe-mode": true };
    }
  }

  return {
    "widget-mode": isWidget,
    "mobile-mode": hostMode === HOST_MODE.MOBILE,
  };
}
