import { useCallback, useEffect, useRef } from "preact/compat";

interface ScrollData {
  scrollToBottom: () => void;
  scrollToTop: () => void;
}

interface HookProps {
  // ref 可能尚未初始化
  scrollContainerRef: React.RefObject<HTMLElement> | undefined;
  scrollToTopHandler?: () => void;
  processing?: boolean; // 如果正在处理中，就不滚动
}

// TODO:当前文件需要重构
const useScroll = ({
  scrollContainerRef,
  scrollToTopHandler,
  processing,
}: HookProps): ScrollData => {
  // 使用 Ref 来保存 scrolling
  const isScrollingRef = useRef(false);
  const scrollTimerRef = useRef<number | null>(null);
  const scrollContainerDOM = (scrollContainerRef as React.RefObject<HTMLElement>)?.current;

  const handleScrollTimer = useCallback(() => {
    clearTimeout(scrollTimerRef.current as number);
    scrollTimerRef.current = window.setTimeout(() => (isScrollingRef.current = false)); // 设置一个适当的延迟时间
  }, [scrollTimerRef.current]);

  useEffect(() => {
    const handleScroll = () => {
      isScrollingRef.current = true;
      handleScrollTimer();
    };

    // pc 端滚动到顶部后，如果有新消息，就拉取
    const handleMoreScroll = (event: WheelEvent) => {
      if (processing) return;
      const delta = event.deltaY;

      if (delta < 0) {
        scrollToTopHandler?.();
      }
    };

    if (scrollContainerDOM) {
      if ("ontouchstart" in window) {
        // 移动端触摸事件
        scrollContainerDOM.addEventListener("touchstart", handleScroll);
        // TODO:移动端顶部下拉事件
      } else {
        // PC滚动事件
        scrollContainerDOM.addEventListener("scroll", handleScroll);
        scrollContainerDOM.addEventListener("wheel", handleMoreScroll);
      }
    }

    return () => {
      if (scrollContainerDOM) {
        if ("ontouchstart" in window) {
          scrollContainerDOM.removeEventListener("touchstart", handleScroll);
        } else {
          scrollContainerDOM.removeEventListener("scroll", handleScroll);
          scrollContainerDOM.removeEventListener("wheel", handleMoreScroll);
        }
      }
    };
  }, [scrollContainerRef?.current, scrollToTopHandler, processing]);

  const scrollToBottom = useCallback(() => {
    if (isScrollingRef.current || !scrollContainerRef?.current) return;
    isScrollingRef.current = true;
    // 执行滚动到底部的操作
    scrollContainerRef?.current?.scrollTo({
      top: scrollContainerRef?.current.scrollHeight,
      behavior: "smooth",
    });
    handleScrollTimer();
  }, [isScrollingRef.current, scrollContainerRef?.current]);

  // 滚动到顶部
  const scrollToTop = useCallback(() => {
    if (isScrollingRef.current || !scrollContainerRef?.current) return;
    isScrollingRef.current = true;

    scrollContainerRef?.current?.scrollTo({ top: 0, behavior: "smooth" });

    handleScrollTimer();
  }, [isScrollingRef.current, scrollContainerRef?.current]);

  return {
    scrollToBottom,
    scrollToTop,
  };
};

export default useScroll;
