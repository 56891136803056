import { useEffect, useState } from "preact/compat";
import dayjs from "dayjs";
import { MessageType } from "~/types";

export interface TimeHooksProps {
  time: string;
  isBot: boolean;
  type: MessageType | undefined;
  hoverRef: React.MutableRefObject<HTMLDivElement | null>;
}

export default function useTime({ time, isBot, type, hoverRef }: TimeHooksProps) {
  const limitRange = getLimit(isBot, type as MessageType);
  const [visible, setVisible] = useState(false);
  const today = dayjs();

  const yesterday = dayjs(today).subtract(1, "day");
  const isYesterday = dayjs(time).isSame(yesterday, "day"); // 昨天

  const previousDay = dayjs(today).subtract(2, "day");
  const isBeforeYesterday = dayjs(time).isBefore(previousDay); // 昨天之前

  const fomat = isBeforeYesterday ? "MMM DD HH:mm" : "HH:mm";
  const value = dayjs(time).format(fomat);

  useEffect(() => {
    if (!hoverRef.current || !limitRange) return;

    const mouseEnter = () => setVisible(true);
    const mouseLeave = () => setVisible(false);

    hoverRef.current.addEventListener("mouseenter", mouseEnter);
    hoverRef.current.addEventListener("mouseleave", mouseLeave);

    return () => {
      hoverRef.current?.removeEventListener("mouseenter", mouseEnter);
      hoverRef.current?.removeEventListener("mouseleave", mouseLeave);
    };
  }, [hoverRef.current]);

  return { visible, value, isYesterday, limitRange };
}

function getLimit(isBot: boolean, type: MessageType) {
  const shouldNot = [
    MessageType.DIVIDER,
    MessageType.SEND_WAITING,
    MessageType.LOADING_STEP,
    MessageType.QUESTION_RECOMMENDR,
  ].includes(type);

  return isBot && !shouldNot;
}
