import { createContext } from "preact";
import { useContext } from "preact/hooks";

import { FormInstance } from "./FormStore";

export interface IFormContext {
  form: FormInstance;
}

export const FormContext = createContext<IFormContext>({} as IFormContext);

export const useFormContext = () => useContext(FormContext);
