import request from "~/utils/request";
import { getLocalAccessToken } from "~/utils/storage";

// 同步 shopline 用户信息
export const syncShoplineUserInfo = ({
  botCode,
  externalUserId,
  tenantId,
}: {
  botCode: string;
  externalUserId: string;
  tenantId: string;
}) =>
  request({
    url: "/user/linkExternalUser",
    method: "POST",
    data: { externalUserId, tenantId },
    params: { botCode },
  });

export const reportInstalled = ({
  botCode,
  tenantId,
  tenantUnitId,
  domain,
}: {
  botCode: string;
  tenantId: string;
  tenantUnitId: string;
  domain: string;
}) =>
  request({
    url: "/bot/status/installed",
    method: "POST",
    data: {
      tenant_unit_id: tenantUnitId,
      tenant_id: tenantId,
      customer_domain: domain,
      has_installed: true,
    },
    params: { botCode },
  });

export const reportBotDisplay = ({
  eventId,
  eventName,
  eventData,
  eventClient,
  agentId,
  timestamp,
  botCode,
}: {
  eventId: string;
  eventName: string;
  eventData: string;
  eventClient: string;
  agentId: string;
  tenantId: string;
  tenantUnitId: string;
  timestamp: string;
  botCode: string;
}) =>
  request({
    url: "/trackEvent",
    method: "POST",
    headers: {
      Authorization: `Bearer ${getLocalAccessToken(botCode)}`,
      "Content-Type": "application/json",
    },
    data: {
      eventId: eventId,
      eventName: eventName,
      eventData: eventData,
      eventClient: eventClient,
      timestamp: timestamp,
    },
    params: { botCode },
  });
