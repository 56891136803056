import { useEffect, useState } from "preact/compat";
import Icon from "@ant-design/icons";
import okSVG from "~/assets/icons/ok.svg?react";
import SendLoading from "./SendLoading/index";
import StepLoading from "./StepLoading";

import "./index.scss";

enum Status {
  LOADING = "loading",
  LOADED = "loaded",
}

interface Step {
  status: Status;
  text: string;
}

interface IProps {
  data: string[];
}

export default function LoadingMessage({ data = [] }: IProps) {
  const [steps, setSteps] = useState<Step[]>([]);

  useEffect(() => {
    if (data.length === 0) return;
    const steps: Step[] = data.map((text) => {
      return {
        status: Status.LOADED,
        text: text,
      };
    });

    steps[steps.length - 1].status = Status.LOADING;

    setSteps(steps);
  }, [data]);

  return (
    <div className="loading-message-box">
      {steps.length ? (
        <>
          {steps.map((step, index) => {
            return (
              <div className="step" key={index}>
                <div className="status">
                  {step.status === "loading" ? <StepLoading /> : <Icon component={okSVG} />}
                </div>
                <div className="text">{step.text}</div>
              </div>
            );
          })}
        </>
      ) : (
        <SendLoading />
      )}
    </div>
  );
}
