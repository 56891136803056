import { Platform, ReportBehavior } from "~/types";
import request from "~/utils/request";

// 统计 Bot 商品相关的行为
export const reportBehavior = ({
  botCode,
  productIds,
  sourcePlatform = "",
  interactionType,
}: {
  botCode: string;
  productIds: string[];
  sourcePlatform?: Platform | "";
  interactionType: ReportBehavior;
}) =>
  request.post(
    "/interactions",
    {
      productIds,
      sourcePlatform,
      interactionType,
    },
    { params: { botCode } }
  );
