export type TenantParams = {
  tenantId: string;
  tenantUnitId: string;
};

export enum RequestStatus {
  Initial,
  Loading,
  Success,
  Fail,
}
