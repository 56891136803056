import React, { Component } from "preact/compat";

interface Props {
  children: React.ReactNode;
  component: React.ReactNode;
  name?: string; // 组件名称
}

interface State {
  hasError: boolean;
}

class BotErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: unknown) {
    console.error(`ErrorBoundary ${this.props.name} caught an error: `, error, errorInfo);
    this.setState({ hasError: true });
  }

  handleRefresh = () => {
    this.forceUpdate();
  };

  render() {
    if (this.state.hasError) {
      return this.props.component;
    }

    return this.props.children;
  }
}

export default BotErrorBoundary;
