import { useEffect, useState } from "preact/compat";
import { MessageSubType, MessageType } from "~/types";

export interface FeedbackHooksProps {
  isBot: boolean;
  type: MessageType | undefined;
  subType: MessageSubType | undefined;
  hoverRef: React.MutableRefObject<HTMLDivElement | null>;
}

export default function useFeedback(props: FeedbackHooksProps) {
  const { isBot, type, subType, hoverRef } = props;
  const [feedbackVisible, setfeedbackVisible] = useState<boolean>(false);

  useEffect(() => {
    // 只针对部分类型的消息显示 feedback
    const ifTypeMatched =
      isBot &&
      type !== MessageType.SEND_WAITING &&
      type !== MessageType.UNAVAILABLE &&
      type !== MessageType.QUESTION_RECOMMENDR &&
      (![MessageSubType.DOWNVOTE, MessageSubType.UPVOTE, MessageSubType.WELCOME].includes(
        subType as MessageSubType
      ) ||
        !subType);

    if (!hoverRef.current || !ifTypeMatched) return;

    const mouseEnter = () => setfeedbackVisible(true);
    const mouseLeave = () => setfeedbackVisible(false);

    hoverRef.current.addEventListener("mouseenter", mouseEnter);
    hoverRef.current.addEventListener("mouseleave", mouseLeave);

    return () => {
      hoverRef.current?.removeEventListener("mouseenter", mouseEnter);
      hoverRef.current?.removeEventListener("mouseleave", mouseLeave);
    };
  }, [hoverRef.current]);

  return feedbackVisible;
}
