import Icon from "@ant-design/icons";
import { useEffect, useRef, useState } from "preact/hooks";
import moreSVG from "~/assets/icons/more.svg?react";
import ringSVG from "~/assets/icons/ring.svg?react";
import silentSVG from "~/assets/icons/silent.svg?react";
import expandSVG from "~/assets/icons/expand.svg?react";
import classNames from "classnames";
import { HOST_MODE } from "~/types";
import { useBotStore, useUserStore } from "~/stores";
import { getLocalAccessToken } from "~/utils/storage";

import "./styles.scss";

const SettingMenu = () => {
  const [active, setActive] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isNotificationSoundMuted, muteNotificationSound } = useUserStore();
  const { integrationConfig, getBotCode } = useBotStore();
  const { hostMode, isWidget } = integrationConfig;

  const handleExpand = () => {
    if (integrationConfig.code) {
      const botDomain = `https://${import.meta.env.VITE_BOT_DOMAIN}`;
      const botLink = `${botDomain}/${integrationConfig.code}?origin=${encodeURIComponent(window.location.origin)}`;
      const botWindow = window.open(botLink, "_blank");

      let tokenSent = false;
      const sendToken = () =>
        botWindow?.postMessage(
          {
            type: "target-pilot-bot-token",
            code: integrationConfig.code,
            token: getLocalAccessToken(integrationConfig.code!),
          },
          botDomain
        );
      let sendTokenTimeout: NodeJS.Timeout | null = null;

      window.addEventListener("message", (e: MessageEvent) => {
        const {
          data: { type, code, origin },
        } = e;
        if (type === "target-pilot-bot-rendered" && origin === window.location.origin) {
          if (!tokenSent) {
            sendToken();
            sendTokenTimeout = setTimeout(() => sendToken, 1000);
          }
        }
        if (
          type === "target-pilot-bot-token-settled" &&
          origin === window.location.origin &&
          code === integrationConfig.code
        ) {
          tokenSent = true;

          if (sendTokenTimeout) {
            clearTimeout(sendTokenTimeout);
          }
        }
      });
    }
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!containerRef.current?.contains(e.composedPath()[0] as HTMLElement)) {
        setActive(false);
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="setting-menu" ref={containerRef}>
      <div
        className={classNames("menu-icon", active ? "active" : "inactive")}
        onClick={() => setActive(!active)}
      >
        <Icon component={moreSVG} />
      </div>
      <div className={classNames("menu-list", active ? "active" : "inactive")}>
        <div
          className="menu-list-item"
          onClick={() => {
            muteNotificationSound(getBotCode(), !isNotificationSoundMuted);
            setActive(false);
          }}
        >
          <Icon component={isNotificationSoundMuted ? silentSVG : ringSVG} />
          <span className="menu-list-item-desc">
            Turn {isNotificationSoundMuted ? "on" : "off"} notification
          </span>
        </div>
        {((isWidget && hostMode !== HOST_MODE.MOBILE) || hostMode === HOST_MODE.IFRAME) && (
          <div
            className="menu-list-item"
            onClick={() => {
              handleExpand();
              setActive(false);
            }}
          >
            <Icon component={expandSVG} />
            <span className="menu-list-item-desc">Full screen</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingMenu;
