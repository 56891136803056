import "./index.scss";

export default function BotLoading() {
  return (
    <div>
      <div className="load-twenty-one">
        <div className="k-letter-holder">
          <div className="k-letter-1 k-letter">L</div>
          <div className="k-letter-2 k-letter">o</div>
          <div className="k-letter-3 k-letter">a</div>
          <div className="k-letter-4 k-letter">d</div>
          <div className="k-letter-5 k-letter">i</div>
          <div className="k-letter-6 k-letter">n</div>
          <div className="k-letter-7 k-letter">g</div>
          <div className="k-letter-8 k-letter">.</div>
          <div className="k-letter-9 k-letter">.</div>
          <div className="k-letter-10 k-letter">.</div>
        </div>
      </div>
    </div>
  );
}
