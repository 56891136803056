import { useState, useEffect } from "preact/compat";
import { reportBehavior } from "~/api/report";
import { ReportBehavior } from "~/types";
import { useBotStore } from "~/stores";

const useInView = (
  elementRef: React.RefObject<HTMLElement>,
  productId: string,
  isHistory: boolean
) => {
  const [marked, setMarked] = useState(false);
  const { getBotCode } = useBotStore();

  useEffect(() => {
    // 不统计历史消息，只统计即时消息的展示
    if (isHistory) return;

    const observer = new IntersectionObserver(([entry]) => {
      // 不重复统计
      if (marked) {
        return;
      }
      setMarked(true);
      reportBehavior({
        botCode: getBotCode(),
        productIds: [productId],
        interactionType: ReportBehavior.DISPLAY,
      });
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [elementRef, marked, isHistory]);
};

export default useInView;
