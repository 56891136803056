export const randomWelcome = [
  "Grow, Laugh, Shop – Life's Joyful Trio.",
  "Kindness Connects Us Beyond Words.",
  "Every Click, A Path to Shared Joy.",
  "Beyond Goods, Discover Life's Treasures.",
  "Cultivate Smiles, Harvest Warmth.",
  "Embrace Each Moment, Journey Anew.",
  "Dreams Meet Reality With Each Step.",
  "Life, Guided by Grace and Joy.",
  "A Gentle Guide on Your Path.",
  "Warmth in Every Word, Kindness in Action.",
];

export function getRandomWelcomeTip() {
  return randomWelcome[Math.floor(Math.random() * randomWelcome.length)];
}
