import { get, set } from "lodash-es";
import type { IRule } from "./FormItem";
import { remove } from "./utils";

export interface FormInstance {
  getFieldValue(name: string): any;
  getFieldValues(): Record<string, any>;

  setFieldValue(name: string, value: any): void;
  setFieldValues(newValues: Record<string, any>): void;

  getFieldError(name: string): string | undefined;
  getFieldErrors(): { name: string; error: string | undefined }[];

  validateFields(): Record<string, string>;

  onFieldChange(name: string, callback: FieldChangeCallback): () => void;
}

export interface InternalFormInstance extends FormInstance {
  getInternalHooks(): {
    registerField(name: string, field: Field): () => void;
    getInitialValues(): Record<string, any>;
    setInitialValues(newValues: Record<string, any>): void;
  };
}

type Field = { name: string; rules?: IRule[] };

type FieldChangeCallback = (error: string | undefined, value: string) => void;

class FormStore implements FormInstance {
  private values: Record<string, any>;
  private fields: Record<string, Field>;
  private errors: Record<string, string>;
  private initialValues: Record<string, any>;
  private callbacks: Record<string, FieldChangeCallback[]>;

  constructor(initialValues?: Record<string, any>) {
    this.values = {};
    this.fields = {};
    this.errors = {};
    this.callbacks = {};
    this.initialValues = initialValues ?? {};
  }
  private setInitialValues = (values?: any) => {
    this.initialValues = values ?? {};
  };

  private getInitialValues = () => {
    return this.initialValues;
  };

  private getInternalHooks = () => {
    return {
      registerField: this.registerField,
      getInitialValues: this.getInitialValues,
      setInitialValues: this.setInitialValues,
    };
  };

  public setFieldValue = (name: string, value: any) => {
    set(this.values, name, value);

    this.validateField(name);
  };

  public getFieldValue = (name: string) => {
    return get(this.values, name);
  };

  public setFieldValues = (newValues: Record<string, any>) => {
    this.values = {
      ...this.values,
      ...newValues,
    };

    this.validateFields();
  };

  public getFieldValues = () => {
    return this.values;
  };

  public validateField = (name: string) => {
    if (!this.fields[name]?.rules) {
      return;
    }

    const filedValue = this.getFieldValue(name);

    delete this.errors[name];

    for (const rule of this.fields[name].rules) {
      if ("required" in rule && rule.required && !filedValue) {
        this.errors[name] = rule.message ?? "validate failed";
        break;
      }

      if ("max" in rule || "min" in rule) {
        const parsedValue = Number.parseInt(filedValue);

        if (Number.isNaN(parsedValue)) {
          continue;
        }

        if (
          ("max" in rule && rule.max < parsedValue) ||
          ("min" in rule && rule.min > parsedValue)
        ) {
          this.errors[name] = rule.message ?? "validate failed";
          break;
        }
      }
    }

    this.callbacks[name].forEach((callback) => {
      callback(this.errors[name], filedValue);
    });
  };

  public validateFields = () => {
    for (const name of Object.keys(this.fields)) {
      this.validateField(name);
    }

    return this.errors;
  };

  public onFieldChange = (name: string, callback: FieldChangeCallback) => {
    if (!this.callbacks[name]) {
      this.callbacks[name] = [];
    }

    this.callbacks[name].push(callback);

    return () => {
      this.callbacks[name] = this.callbacks[name].filter((ca) => ca !== callback);
    };
  };

  public registerField = (name: string, field: Field) => {
    this.fields[name] = field;

    return () => {
      delete this.fields[name];
      delete this.errors[name];
      remove(this.values, name);
    };
  };

  public getFieldError = (name: string) => {
    return this.errors[name];
  };

  public getFieldErrors = () => {
    return Object.keys(this.errors)
      .filter((name) => this.errors[name])
      .map((name) => ({ name, error: this.errors[name] }));
  };

  public getForm = (): InternalFormInstance => {
    return {
      getFieldValue: this.getFieldValue,
      setFieldValue: this.setFieldValue,
      getFieldValues: this.getFieldValues,
      setFieldValues: this.setFieldValues,
      getFieldError: this.getFieldError,
      getFieldErrors: this.getFieldErrors,
      validateFields: this.validateFields,
      onFieldChange: this.onFieldChange,
      getInternalHooks: this.getInternalHooks,
    };
  };
}

export default FormStore;
