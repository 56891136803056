import { type StateCreator } from "zustand";
import { BotServiceStatus, ISettingMessage } from "~/types";

export interface Customize {
  avatar: string;
  botName: string;
  brandColor: string;
  sound: boolean;
  draggable: boolean;
  initPosition?: {
    right?: number;
    bottom?: number;
  };
  recommendQuestions: string[];
  palettes: string[];
  defaultMessageSetting: Partial<ISettingMessage>;
  serviceStatus: BotServiceStatus;
  customerContact: Record<string, string>;
  logoRemoved: boolean;
  orderTrackingConfig?: {
    enable?: boolean;
  };
}

export interface CustomizeStore {
  customize: Customize;
  updateCustomize: (customize: Partial<Customize>) => void;
}

export const defaultMessageSetting = {
  welcome: "",
  upvote: "",
  unanswerable: "",
  downvote: "",
  placeholder: "",
};

const defaultValue = {
  avatar: "",
  botName: "",
  brandColor: "",
  recommendQuestions: [],
  palettes: [],
  defaultMessageSetting,
  serviceStatus: BotServiceStatus.PENDING,
  customerContact: {},
  logoRemoved: false,
  sound: true,
  draggable: true,
  orderTrackingConfig: {
    enable: true,
  },
};

const customizeStore: StateCreator<CustomizeStore> = (set) => ({
  customize: defaultValue,

  updateCustomize: (customize) => {
    set((state) => ({ ...state, customize: { ...state.customize, ...customize } }));
  },
});

export default customizeStore;
