import React, { Component } from "preact/compat";

import "./index.scss";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class MessageItemErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: unknown) {
    console.error("MessageItemErrorBoundary caught an error: ", error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-message-item">
          Opps！ Sorry something went wrong, I’m out of service and can’t get the real-time data
          currently, please try again later.
        </div>
      );
    }

    return this.props.children;
  }
}

export default MessageItemErrorBoundary;
