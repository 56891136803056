/**
 * shopline 的用户信息，分为两种情况，即匿名用户和具名用户
 * Tips：
 * 匿名用户，即不登录使用，也可以直接下单购买商品。需要从 cookie 中获取 osudb_uid 字段
 * 具名用户，登录使用，据 shopline 官方回复，这个比例不足 5%，需要从 window.Shopline 中获取用户信息
 *
 */

function parseCookie() {
  const cookieString = document.cookie;
  const cookies: Record<string, string> = {};

  cookieString.split("; ").forEach((cookie) => {
    const [key, value] = cookie.split("=");
    cookies[key] = decodeURIComponent(value);
  });

  return cookies;
}

export function getShoplineUserId() {
  let isLoginedUser = false;
  let userId = "";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-expect-error
  if (window.Shopline.customer) {
    isLoginedUser = true;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    userId = window.Shopline.customer.id;
  } else {
    const cookies = parseCookie();
    userId = cookies.a_osudb_uid || "";
  }

  if (!userId) {
    console.warn("shopline user id is empty");
  }

  return userId;
}
