import { type StateCreator } from "zustand";
import { Message, MessageState, MessageType } from "~/types";
import { mockMessages } from "./mock";

export interface MessageStore {
  messageState: MessageState;
  messages: Message[];
  setMessageState: (messageState: MessageState) => void;
  appendMessage: (message: Message) => void;
  appendMessages: (messages: Message[]) => void;
  prependMessages: (messages: Message[]) => void;
  removePendingMessage: () => void;
  updateMessage: (message: Message) => void;
  markLatestMessageDone: () => void;
}

const defaultMessages: Message[] = mockMessages;

const messageStore: StateCreator<MessageStore> = (set) => ({
  messageState: MessageState.AVAILABLE,
  setMessageState: (messageState: MessageState) => set({ messageState }),

  messages: defaultMessages,
  appendMessage: (message: Message) => {
    set((state) => {
      return { messages: [...state.messages, message] };
    });
  },

  appendMessages: (messages: Message[]) => {
    set((state) => ({ messages: [...state.messages, ...messages] }));
  },

  prependMessages: (messages: Message[]) =>
    set((state) => ({ messages: [...messages, ...state.messages] })),

  removePendingMessage: () =>
    set((state) => {
      const pendingTypes = [MessageType.SEND_WAITING];
      const messages = state.messages.filter((m) => !pendingTypes.includes(m.type as MessageType));
      return { messages };
    }),

  updateMessage: (message: Message) =>
    set((state) => {
      const messages = state.messages;
      const targetIndex = messages.findIndex((m) => m.messageId === message.messageId);
      messages[targetIndex] = message;
      return { messages };
    }),

  markLatestMessageDone: () =>
    set((state) => {
      const messages = state.messages;
      const latestMessage = messages[messages.length - 1];
      latestMessage.done = true;
      return { messages, messageState: MessageState.AVAILABLE };
    }),
});

export default messageStore;
